import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import locale from './locale';
import authentication from './authentication';
import applicationProfile from './application-profile';

import administration from 'app/modules/administration/administration.reducer';
import userManagement from 'app/modules/administration/user-management/user-management.reducer';
import register from 'app/modules/account/register/register.reducer';
import activate from 'app/modules/account/activate/activate.reducer';
import password from 'app/modules/account/password/password.reducer';
import settings from 'app/modules/account/settings/settings.reducer';
import passwordReset from 'app/modules/account/password-reset/password-reset.reducer';
// prettier-ignore
import parCountry from 'app/entities/par-country/par-country.reducer';
// prettier-ignore
import parRegion from 'app/entities/par-region/par-region.reducer';
// prettier-ignore
import parCity from 'app/entities/par-city/par-city.reducer';
// prettier-ignore
import metProductNature from 'app/entities/met-product-nature/met-product-nature.reducer';
// prettier-ignore
import consTypeProductNature from 'app/entities/cons-type-product-nature/cons-type-product-nature.reducer';
// prettier-ignore
import parProductUnit from 'app/entities/par-product-unit/par-product-unit.reducer';
// prettier-ignore
import metCanalDonation from 'app/entities/met-canal-donation/met-canal-donation.reducer';
// prettier-ignore
import consTypeDonationDocument from 'app/entities/cons-type-donation-document/cons-type-donation-document.reducer';
// prettier-ignore
import parDonorStatus from 'app/entities/par-donor-status/par-donor-status.reducer';
// prettier-ignore
import consTypeDonorDocument from 'app/entities/cons-type-donor-document/cons-type-donor-document.reducer';
// prettier-ignore
import metActivitySector from 'app/entities/met-activity-sector/met-activity-sector.reducer';
// prettier-ignore
import metDonorContactFunction from 'app/entities/met-donor-contact-function/met-donor-contact-function.reducer';
// prettier-ignore
import metCanalCommunication from 'app/entities/met-canal-communication/met-canal-communication.reducer';
// prettier-ignore
import parLanguageCommunication from 'app/entities/par-language-communication/par-language-communication.reducer';
// prettier-ignore
import parTypeIdentity from 'app/entities/par-type-identity/par-type-identity.reducer';
// prettier-ignore
import metProfession from 'app/entities/met-profession/met-profession.reducer';
// prettier-ignore
import parStatus from 'app/entities/par-status/par-status.reducer';
// prettier-ignore
import metCategory from 'app/entities/met-category/met-category.reducer';
// prettier-ignore
import metService from 'app/entities/met-service/met-service.reducer';
// prettier-ignore
import consCardType from 'app/entities/cons-card-type/cons-card-type.reducer';
// prettier-ignore
import metHealthInsurance from 'app/entities/met-health-insurance/met-health-insurance.reducer';
// prettier-ignore
import metOperationService from 'app/entities/met-operation-service/met-operation-service.reducer';
// prettier-ignore
import metDiseases from 'app/entities/met-diseases/met-diseases.reducer';
// prettier-ignore
import metAllergies from 'app/entities/met-allergies/met-allergies.reducer';
// prettier-ignore
import metHandicapType from 'app/entities/met-handicap-type/met-handicap-type.reducer';
// prettier-ignore
import consBeneficiaryDocumentType from 'app/entities/cons-beneficiary-document-type/cons-beneficiary-document-type.reducer';
// prettier-ignore
import metDiseaseTreatmentType from 'app/entities/met-disease-treatment-type/met-disease-treatment-type.reducer';
// prettier-ignore
import metEps from 'app/entities/met-eps/met-eps.reducer';
// prettier-ignore
import metScholarship from 'app/entities/met-scholarship/met-scholarship.reducer';
// prettier-ignore
import parSchoolLevel from 'app/entities/par-school-level/par-school-level.reducer';
// prettier-ignore
import parSchoolYear from 'app/entities/par-school-year/par-school-year.reducer';
// prettier-ignore
import parHonor from 'app/entities/par-honor/par-honor.reducer';
// prettier-ignore
import parMajor from 'app/entities/par-major/par-major.reducer';
// prettier-ignore
import metIncomeSource from 'app/entities/met-income-source/met-income-source.reducer';
// prettier-ignore
import consEducationDocumentType from 'app/entities/cons-education-document-type/cons-education-document-type.reducer';
// prettier-ignore
import consMemberDocumentType from 'app/entities/cons-member-document-type/cons-member-document-type.reducer';
// prettier-ignore
import metFamilyRelationship from 'app/entities/met-family-relationship/met-family-relationship.reducer';
// prettier-ignore
import consFamilyDocumentType from 'app/entities/cons-family-document-type/cons-family-document-type.reducer';
// prettier-ignore
import consTakeInChargeDocumentType from 'app/entities/cons-take-in-charge-document-type/cons-take-in-charge-document-type.reducer';
// prettier-ignore
import metTypeDonorMoral from 'app/entities/met-type-donor-moral/met-type-donor-moral.reducer';
// prettier-ignore
import parCurrency from 'app/entities/par-currency/par-currency.reducer';
// prettier-ignore
import actionStatus from 'app/entities/action-status/action-status.reducer';
// prettier-ignore
import metSourceBeneficiary from 'app/entities/met-source-beneficiary/met-source-beneficiary.reducer';
import parAccommodationType from 'app/entities/par-accommodation-type/par-accommodation-type.reducer';
import parTypeKafalat from 'app/entities/par-type-kafalat/par-type-kafalat.reducer';
import parTypePriseEnCharge from 'app/entities/par-type-prise-en-charge/par-type-prise-en-charge.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const rootReducer = {
  authentication,
  actionStatus,
  metSourceBeneficiary,
  locale,
  applicationProfile,
  administration,
  userManagement,
  register,
  activate,
  passwordReset,
  password,
  settings,
  parCountry,
  parRegion,
  parCity,
  metProductNature,
  consTypeProductNature,
  parProductUnit,
  metCanalDonation,
  consTypeDonationDocument,
  parDonorStatus,
  consTypeDonorDocument,
  metActivitySector,
  metDonorContactFunction,
  metCanalCommunication,
  parLanguageCommunication,
  parAccommodationType,
  parTypeIdentity,
  metProfession,
  parStatus,
  metCategory,
  metService,
  consCardType,
  metHealthInsurance,
  metOperationService,
  metDiseases,
  metAllergies,
  metHandicapType,
  consBeneficiaryDocumentType,
  metDiseaseTreatmentType,
  metEps,
  metScholarship,
  parSchoolLevel,
  parSchoolYear,
  parHonor,
  parMajor,
  metIncomeSource,
  consEducationDocumentType,
  consMemberDocumentType,
  metFamilyRelationship,
  consFamilyDocumentType,
  consTakeInChargeDocumentType,
  metTypeDonorMoral,
  parCurrency,
  parTypeKafalat,
  parTypePriseEnCharge,

  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  loadingBar,
};

export default rootReducer;
