import { Status } from 'app/shared/model/enumerations/status.model';
import { IMetCategory } from 'app/shared/model/met-category.model';

export interface IParTypePriseEnCharge {
  id?: number;
  code?: string;
  name?: string;
  nameAr?: string;
  nameEn?: string;
  status?: Status;
  category?: IMetCategory | null;
}

export const defaultValue: Readonly<IParTypePriseEnCharge> = {};
