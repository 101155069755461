import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import MetSourceBeneficiary from './met-source-beneficiary';
import MetSourceBeneficiaryDetail from './met-source-beneficiary-detail';
import MetSourceBeneficiaryUpdate from './met-source-beneficiary-update';
import MetSourceBeneficiaryDeleteDialog from './met-source-beneficiary-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={MetSourceBeneficiaryUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={MetSourceBeneficiaryUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={MetSourceBeneficiaryDetail} />
      <ErrorBoundaryRoute path={match.url} component={MetSourceBeneficiary} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={MetSourceBeneficiaryDeleteDialog} />
  </>
);

export default Routes;
