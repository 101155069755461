import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntities } from './par-type-prise-en-charge.reducer'; // Changer le chemin ici
import { IParTypePriseEnCharge } from 'app/shared/model/par-type-prise-en-charge.model'; // Changer le modèle ici
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const ParTypePriseEnCharge = (props: RouteComponentProps<{ url: string }>) => {
  const dispatch = useAppDispatch();

  const parTypePriseEnChargeList = useAppSelector(state => state.parTypePriseEnCharge.entities);
  const loading = useAppSelector(state => state.parTypePriseEnCharge.loading);

  useEffect(() => {
    dispatch(getEntities({}));
  }, [dispatch]);

  const handleSyncList = () => {
    dispatch(getEntities({}));
  };

  const { match } = props;

  return (
    <div>
      <h2 id="par-type-prise-en-charge-heading" data-cy="ParTypePriseEnChargeHeading">
        <Translate contentKey="almobadaraMasterDataApp.parTypePriseEnCharge.home.title">Par Type Prise En Charge</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="almobadaraMasterDataApp.parTypePriseEnCharge.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to={`${match.url}/new`} className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="almobadaraMasterDataApp.parTypePriseEnCharge.home.createLabel">
              Create new Par Type Prise En Charge
            </Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {parTypePriseEnChargeList && parTypePriseEnChargeList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <Translate contentKey="almobadaraMasterDataApp.parTypePriseEnCharge.id">ID</Translate>
                </th>
                <th>
                  <Translate contentKey="almobadaraMasterDataApp.parTypePriseEnCharge.code">Code</Translate>
                </th>
                <th>
                  <Translate contentKey="almobadaraMasterDataApp.parTypePriseEnCharge.name">Name</Translate>
                </th>
                <th>
                  <Translate contentKey="almobadaraMasterDataApp.parTypePriseEnCharge.nameAr">Name ar</Translate>
                </th>
                <th>
                  <Translate contentKey="almobadaraMasterDataApp.parTypePriseEnCharge.nameEn">Name en</Translate>
                </th>
                <th>
                  <Translate contentKey="almobadaraMasterDataApp.parTypePriseEnCharge.status">Status</Translate>
                </th>
                <th>
                  <Translate contentKey="almobadaraMasterDataApp.parTypePriseEnCharge.category">Category</Translate>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {parTypePriseEnChargeList.map((parTypePriseEnCharge, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`${match.url}/${parTypePriseEnCharge.id}`} color="link" size="sm">
                      {parTypePriseEnCharge.id}
                    </Button>
                  </td>
                  <td>{parTypePriseEnCharge.code}</td>
                  <td>{parTypePriseEnCharge.name}</td>
                  <td>{parTypePriseEnCharge.nameAr}</td>
                  <td>{parTypePriseEnCharge.nameEn}</td>
                  <td>
                    <Translate contentKey={`almobadaraMasterDataApp.Status.${parTypePriseEnCharge.status}`} />
                  </td>
                  <td>
                    {parTypePriseEnCharge.category ? (
                      <Link to={`met-category/${parTypePriseEnCharge.category.id}`}>{parTypePriseEnCharge.category.name}</Link>
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button
                        tag={Link}
                        to={`${match.url}/${parTypePriseEnCharge.id}`}
                        color="info"
                        size="sm"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${parTypePriseEnCharge.id}/edit`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${parTypePriseEnCharge.id}/delete`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Archive</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="almobadaraMasterDataApp.parTypePriseEnCharge.home.notFound">
                No Par Type Prise En Charge found
              </Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ParTypePriseEnCharge;
