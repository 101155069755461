import { IParStatus } from 'app/shared/model/par-status.model';
import { Status } from 'app/shared/model/enumerations/status.model';

export interface IMetCategory {
  id?: number;
  code?: string;
  name?: string;
  nameAr?: string;
  nameEn?: string;
  abreviation?: string;
  status?: Status;
  statuses?: IParStatus[] | null;
}

export const defaultValue: Readonly<IMetCategory> = {};
